import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalstorageService } from '@yuno/angular/services';

import { TOKEN_KEY } from './../+state/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
	constructor(private localStorage: LocalstorageService) {}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const jwt = this.localStorage.getItem(TOKEN_KEY);
		const cloned = req.clone({
			setHeaders: {
				Authorization: 'Bearer ' + jwt
			}
		});

		return jwt ? next.handle(cloned) : next.handle(req);
	}
}
